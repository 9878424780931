
import { Vue, Component } from "vue-property-decorator";
import { Course, CourseCollection } from "@planetadeleste/vue-mc-learning";
import { Youtube } from "vue-youtube";
import sBackgroundImg from "@/assets/img/hero/h1.jpg";
import HomeHero from "@/modules/home/components/HomeHero.vue";
import BSection from "@/components/bs/BSection.vue";
import CourseListing from "@/modules/courses/components/CourseListing.vue";
import CourseView from "@/modules/courses/components/CourseView.vue";
import InterviewsListing from "@/modules/interviews/components/InterviewsListing.vue";
import InstructorArticle from "@/modules/instructor/components/InstructorArticle.vue";
import TestimonialsListing from "@/modules/testimonials/components/TestimonialsListing.vue";
import { ArticleData } from "@planetadeleste/vue-mc-goodnews";
import { EventBus } from "@/services/event-bus";
import { LoadingModule } from "@/store/loading";
import CourseSidebar from "@/modules/courses/components/course/CourseSidebar.vue";
import ContactForm from "@/modules/contact/components/ContactForm.vue";

@Component({
  components: {
    ContactForm,
    CourseSidebar,
    HomeHero,
    BSection,
    CourseListing,
    CourseView,
    Youtube,
    InterviewsListing,
    InstructorArticle,
    TestimonialsListing,
  },
})
export default class Home extends Vue {
  obCourseCollection: CourseCollection = new CourseCollection();
  sInsctructorBg: string | null = null;

  mounted(): void {
    EventBus.on("login.success", this.load);
    EventBus.on("logout.success", this.load);

    this.$nextTick(this.load);
  }

  beforeDestroy(): void {
    EventBus.off("login.success");
    EventBus.off("logout.success");
  }

  onLoadedInstructor(obArticle: ArticleData): void {
    if (obArticle.images && obArticle.images.length) {
      this.sInsctructorBg = obArticle.images[0].path;
    }
  }

  async load(): Promise<void> {
    LoadingModule.loading();

    this.obCourseCollection.clear();
    this.obCourseCollection.page(1);
    await this.obCourseCollection.fetch();

    LoadingModule.loaded();
  }

  get mainCourse(): Course | null | undefined {
    return this.obCourseCollection.length === 1
      ? this.obCourseCollection.first()
      : null;
  }

  get bgImg(): string {
    return sBackgroundImg;
  }
}
