
import { AppModule } from "@/store/app";
import { ArticleCollection, Category } from "@planetadeleste/vue-mc-goodnews";
import { Vue, Component } from "vue-property-decorator";
import TestimonialItem from "@/modules/testimonials/components/TestimonialItem.vue";

@Component({
  components: { TestimonialItem },
})
export default class TestimonialsListing extends Vue {
  obCollection = new ArticleCollection();

  mounted(): void {
    this.$nextTick(this.load);
  }

  async load(): Promise<void> {
    if (!this.category) {
      return;
    }

    this.obCollection.filterBy({ category: this.category.id, active: true });
    await this.obCollection.fetch();
  }

  get category(): Category | undefined {
    return AppModule.blogCategories.find({ slug: "testimonials" });
  }
}
